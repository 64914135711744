﻿@import 'mixins';
@import 'variables';


/**** Department menu ****/

.mc-menu-overlay .mc-m-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
    background: #fff;
    background: transparent;
}

.mc-overlay {
    position: fixed;
    will-change: scroll-position;
    width: 100%;
    height: 100%;
    /*background: #000;*/
    z-index: 100;
    left: 0;
    top: 0;
    display: none;
    opacity: 0;
    transition: opacity .6s ease-out;
}

.mc-menu-overlay .mc-overlay {
    display: block;
    opacity: 0.3;
}

.mcm .dropdown.mcm-fw .dropdown-menu {
    border-radius: 2px;
}

.nav-btn-1 {
    color: #fff;
    cursor: pointer;
}

.nav-btn-2,
.nav-btn-3 {
    color: #666;
    cursor: pointer;
}

li.nav-lev-1 {
    display: inline-block;
    padding: 0px 10px;
}

a.nav-link-1 {
    color: #fff;
}

a.nav-link-2,
a.nav-link-3,
a.nav-link-4 {
    color: #666;
}



.nav-content-1 {
    display: none;
    float: left;
    position: absolute;
    left: 50px;
    background-color: #fff;
    width: 300px;
    font-size: 16px;
}


.nav-content-2 {
    display: none;
    left: 300px;
    top: 0;
    position: absolute;
    width: 300px;
    font-size: 16px;


    ul {
        display: flex;
        flex-flow: row wrap;
        height: auto;
        min-width: 800px;
        background: #EEE;
        min-height: 300px;
    }
}

.nav-content-1.active,
.nav-content-2.active {
    display: block;
}


.nav-2 li,
.nav-3 li,
.nav-4 li {
    display: block;
}


.navbar.navbar-default.mcm {
    z-index: 103;
    &:hover {
        z-index: 130;
    }
}
