﻿@import 'variables';
@import 'mixins';

.ProductMenu {
	padding-bottom: 10px;
    width: 100%;
}
.ProductMenu ul {
	list-style-type: none;
    padding-left: 0;
}
.ProductMenu li {
	font-size: 16px;
	padding: 4px 0 4px 30px;

    @include toSizeMD {
        padding-left: 10px;
        font-size: 14px;
    }
}
.ProductMenu .Level2,
.ProductMenu .Level2Selected {
	padding-left: 45px;

    @include toSizeMD {
        padding-left: 20px;
    }
}
.ProductMenu .Level3,
.ProductMenu .Level3Selected {
	padding-left: 60px;
	font-size: 14px;

    @include toSizeMD {
        padding-left: 30px;
    }
}
.ProductMenu .Level4,
.ProductMenu .Level4Selected {
	padding-left: 70px;
	font-size: 14px;

    @include toSizeMD {
        padding-left: 40px;
    }
}
.ProductMenu .ProductMenuHeading {
	text-transform: uppercase;
	padding-left: 20px;
	font-weight: bold;
	font-family: $menuFont;
	font-size: 26px;

    @include toSizeMD {
        padding-left: 0;
    }
}
.ProductMenu .Level1Selected {
	border-radius: 10px;
}
.ProductMenu a {
	width: 100%;
	display: inline-block;
    white-space: nowrap;
}

#PageColumnLeft .PublisherContainer {
    float: left;
    width: 100%;
    clear: both;
}

#PageColumnLeft .ProductMenuContainer {
    clear: both;
}