﻿@import 'variables';
@import 'mixins';

$borderColor: #d4e0ec;

/* Deprecated in D4? */

/*.container*/
.layout-d2016listview {
    padding-left: 0;
    padding-right: 0;

    .web-pub-field-sort {
        padding-left: 15px;
        padding-right: 15px;
    }

    .WebPubElement {
        margin-bottom: 0;
    }
}

.WebPubElement .ads-lv {
    border-left: none;
    border-right: none;
    border-bottom: none;
    background: none;
    font-size: 12px;
    text-align: left;

    &.disable-link {
        &:hover {
            border-color: $borderColor;
            cursor: default;
        }

        .lv-img a:hover {
            cursor: default;
        }
    }
}

.Layout3Element .YouSavePercentLabel.lv-percent {
    right: 5px;
    left: initial;
    width: 40px;
    height: 40px;
    font-size: 12px;
    padding-top: 12px;

    @include fromSizeSM {
        top: 5px;
        width: 50px;
        height: 50px;
        font-size: 16px;
        padding-top: 14px;
    }
}

/* Move to Theme Scantrade Edugnad */
.lv-logo {
    display: none;
}
/* END - Move to Theme Scantrade Edugnad */

.lv-info {
    margin: 5px 15px 10px -10px;
}

.lv-header1 {
    font-weight: bold;
    display: block;
}

.lv-header2 {
    display: block;
    color: #888;
    line-height: 12px;
    padding-bottom: 5px;
}

.lv-stock-container {
    padding-top: 15px;
    padding-bottom: 5px;
    padding-right: 15px;
    text-align: right;
    font-style: italic;
    color: #888;

    @include fromSizeSM {
        padding-right: 15px;
    }

    .AddStockLabel {
        display: inline-block;

        &:before,
        &:after {
            content: '';
            padding-left: 2px;
        }
    }
    /* Move to Theme Scantrade Edugnad */
    .AddStockIcon {
        display: none;
    }

    .in-stock-text,
    .in-stock-text-back {
        display: none;
    }
    /* END - Move to Theme Scantrade Edugnad */
}

.lv-no-btn {
    padding-top: 17px;

    @include fromSizeSM {
        padding-top: 0;
    }
}

.lv-3 {

    .AddPriceContainer {
        padding-top: 5px;

        @include fromSizeSM {
            padding: 10px 0 15px 0;
        }

        @include fromSizeMD {
            padding-top: 30px;
        }
    }

    .AddPriceLabel {
        display: block;

        @include fromSizeSM {
            font-size: 18px;
        }
    }

    .AddVeilPriceLabel {
        display: block;
        margin-right: 10px;
        margin-bottom: 15px;
        float: right;        
    }

    /* Move to Theme Scantrade Edugnad */
    .AdPriceSuffix {
        display: none;
    }        
    /* END - Move to Theme Scantrade Edugnad */
    .less-qty,
    .more-qty {
        width: 37px;
        height: 37px;
        line-height: 35px;
    }

    .QuantityTextBox {
        background: transparent;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        border: 1px solid #434244;
        color: #434244;
    }

    .api-qty {
        padding-bottom: 10px;

        @include fromSizeMD {
            padding: 20px 0 5px 0;
        }
    }

    .lv-hide-btn.lv-btn-container {
        margin-top: 5px;
        margin-right: 15px;
        @include fromSizeSM {
                    margin-right: 0;
        }
    }

    .lv-btn-container {
        padding-right: 0;        
        text-align: right;

        .lv-buy-btn {
            min-width: 130px;
            @include fromSizeMD {
                    margin-top: 20px;
            }
        }
        .lv-favorite-btn{
            @include fromSizeMD {
                margin-top: 20px;
            }
        }
    }
}




.filter-fix-footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 15px 10px;
    z-index: 99;
    background: #fff;
    border-top: 1px solid #ddd;

    @include fromSizeMD {
        position: relative;
        border: none;
        padding: 0;
        margin: 5px 0 15px 0;
        background: transparent;
        float: right;

        .buy-all-content {
            padding: 0;
        }
    }
}
