﻿@import 'mixins';

/*Login*/
#loginout-content {
    display: inline;
}

.login-container {
    float: right;
    margin-top: 40px;
}
.body-out .login-container {
    position: relative;
}

.body-in .login-container {
    float: right;
}

.LoginUserInfo {
    display: inline-block;
    margin-right: 1em;
}

.body-in .kundersenterIcon {
    display: inline-block;
    vertical-align: top;
    margin: 0px 16px 0px 16px;
    width: 32px;
    height: 32px;
    background: inline('images/service.png');
    background-size: 32px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
}

.body-in .LoginUserInfo {
    display: inline-block;
    margin: 0;
    position: absolute;
    right: 0px;
    top : 100px;
    white-space: nowrap;

    &::after {
        font-size: 14px;
        color: black;
    }
}


.body-out .login-not-me {
    margin-left: 3px;
    margin-right: 3px;
    display: inline;
    font-size: 10px;
    cursor: pointer;
}

.body-out .login-details {
    position: absolute;
    bottom: -18px;
    right: 0;
    text-align: center;
    width: auto;
    padding: 0px;
    white-space: nowrap;
}

.body-in .login-details {
    display: inline;
}

.LogInButton {
    padding-top: 37px;
    background: inline('images/lock.png');
    background-repeat: no-repeat;
    background-size: 21px 31px;
    background-position-x: center;
    display: inline-block;
}

.refresh-txt {
    display:none;
}


@include toSizeSM {
    #login {

        .pub-area-a{
            margin:0;
        }

        .form-group.login-warning,
        .login-left {
            margin-bottom: 5px;
        }

        .login-header h2 {
            padding: 0;
            margin-top: 5px;
        }
    }
}

@include toSizeSM {
    #center-popup 
    {
        padding-right: 0 !important;

        .lightbox-close {
            margin-top: 7px;
            margin-right: 0;
            background-size: 100%;
            right: 7px;
            width: 30px;
            height: 30px;
        }
    }

    .form-horizontal .login-password a.control-label, .form-horizontal .password-cancel a.control-label {
        text-align: center;
    }
    .form-group.login-remember {
        text-align: center;
    }
    .login-customer-text p {
        font-size: 13px;
    }

    #lightboxCustomerRegistration .registration-req-info {
        margin-bottom: -15px;
        margin-top: 15px;
    }
    #login .login-password{
        margin-top:15px;
    }

    .form-group.login-g-btn {
        margin-bottom: 5px;
    }
}

@include fromSizeSM {
    #center-popup 
    {
        padding-top: 20px;
        padding-bottom: 20px;
    }    
}


.reset-password label {
    margin-top: 4px;
    margin-bottom: 10px;
}
