﻿@import 'mixins';

.review-container {
    .reviews-summary {
        width: 100%;
        clear: both;
        margin-bottom: 30px;
        overflow: hidden;
        text-align: center;
    }

    .average-score {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
    }
}

.reviewed-product {
    display: inline-block;
    width: 100%;
    margin-bottom:0.4em;
}

@media (min-width: 768px) {
    .modal-dialog.customer-review-window {
        width: 470px;
        margin: 30px auto;
    }
}

.ProductInfo .review-container {
    margin: 0 1em;

    .D4Standard {
        .row {
            width: 100%;
        }
    }
}

.D4Standard.review-element {
    text-align: left;
}

.D4Standard.review-element .review-score {
    position: static;
    width: 100%;
}

.D4Standard.review-element .review-inappropriate {
    @include toSizeMD {
        margin-left: 0.5em;
        display: inline-block;
    }

    @include fromSizeMD {
        display: block;
    }
}

@include toSizeMD {
    .D4Standard.review-element .ReviewDate,
    body.body-product-info .D4Standard.review-element .ReviewerLink.only-show-on-product-page {
        display: inline;
    }

    .D4Standard.review-element .ReviewerLink:after {
        content: ' - ';
    }
}

.D4Standard.review-element .prod-desc1 {
    font-weight: bold;
    margin-right: 1em;
    display:block;
}

.layout-d4_x1 .D4Standard.review-element {
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid #ddd;
    padding-top: 10px;

    .row {
        width: 100%;
    }
}

.btn-review {
    margin-top: 12px;
}

.review-desc {
    text-align: center;
}

.review-score {
    font-size: 30px;
}

.review-name {
    width: 200px;
}

.review-img {
    margin-bottom: 10px;
}

.review-btn {
    text-align: right;
}

.review-img {
    text-align: center;
}

.review-user {
    margin-top: 10px;
}

.rev-error {
    border: solid 1px red;
}

.rev-red {
    color: red;
}

.review-star-icon {
    font-size: 20px;
    color: #FFD700;

    i::before {
        margin: 0;
    }
}

.review-score {
    text-align: initial;
}

.rating {
    direction: rtl;
}

.rating > div {
    display: inline-block;
    position: relative;
    width: 1.1em;
}

.rating > div:hover,
.rating > div.active:hover,
.rating > div:hover ~ div {
    color: #FFED85;
}

.rating > div.active {
    color: #FFD700;
}

.D4Standard.review-element {
    .review-reply {
        border: 1px solid #ddd;
        margin: 1em;
        padding: 1em;
        background: #f8f8f8;

        .review-reply-heading {
            font-weight: bold;
            font-style: italic;
            display: inline-block;
            width: 100%;
        }

        .review-reply-signature {
            font-style: italic;
            display: inline-block;
            width: 100%;
        }
    }

    .linkbutton,
    .review-inappropriate {
        cursor: pointer;
    }

    .review-reply-actions .linkbutton {
        display: inline-block;
        margin-right: 1em;
    }
}

body.body-product-info .hide-from-product-page {
    display: none;
}

.only-show-on-product-page {
    display: none;
}

body.body-product-info .only-show-on-product-page {
    display: block;
}

.review-container
    .ReviewText {
        white-space: pre-line;
}
