﻿$main-color: #d4e0ec;
$secondary-color: #39c;
$text-color-primary: #fff;
$text-color-secondary: #343434;

.toggleDefaultKontaktCheckbox {
    display: none;
}

.toggleDefaultKontaktContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: fit-content;
    border: 0.215em solid $main-color;
    background: $main-color;
    font-weight: bold;
    color: $text-color-primary;
    cursor: pointer;
}

.toggleDefaultKontaktContainer::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0%;
    background: $secondary-color;
    transition: all 0.3s;
}

.toggleDefaultKontaktContainer div {
    padding: 0.43em;
    text-align: center;
    z-index: 1;
}

.toggleDefaultKontaktCheckbox:checked + .toggleDefaultKontaktContainer::before {
    left: 50%;
}

.toggleDefaultKontaktCheckbox:checked + .toggleDefaultKontaktContainer div:first-child {
    color: $text-color-secondary;
    transition: color 0.3s;
}

.toggleDefaultKontaktCheckbox:checked + .toggleDefaultKontaktContainer div:last-child {
    color: $text-color-primary;
    transition: color 0.3s;
}

.toggleDefaultKontaktCheckbox + .toggleDefaultKontaktContainer div:first-child {
    color: $text-color-primary;
    transition: color 0.3s;
}

.toggleDefaultKontaktCheckbox + .toggleDefaultKontaktContainer div:last-child {
    color: $text-color-secondary;
    transition: color 0.3s;
}
