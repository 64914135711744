﻿.variant-slider {
    height: 100%;
    max-height: 320px;
}

.variant-slider .rsThumbs {
    padding-top: 5px;
    height: auto;
    padding-bottom: 5px;
    bottom: 35px;
}

.variant-slider .rsThumb {
    background: transparent;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    border-radius: 50%;

    &:hover {
        border-color: #333;
        box-shadow: 0px 0px 2px rgba(0,0,0,0.5); 
    }
}

.variant-slider .rsTmb {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.variant-slider .rsThumb.rsNavSelected {
    background: transparent;
    border-color: #333;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.5);
}

.variant-slider .rsLink {
    text-indent: -9999em !important;
    -webkit-backface-visibility: hidden;
}

.variant-slider > .rsContent {
    display: none;
}

.InstantSearch {
    & .d4-instant-search-accessories .variant-slider,
    & .d4-instant-search-alternatives .variant-slider {
        background: transparent;

        .rsThumbs {
            background: transparent;
            bottom: 10px;
        }

        .rsThumb {
            width: 10px;
            height: 10px;
        }
    }

    & .d4-instant-search-preview-template .variant-slider .rsThumbs {
        background: linear-gradient(180deg, rgba(255,255,255, 0), rgba(255,255,255, 0));
    }
}
