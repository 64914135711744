﻿@import 'mixins';

/* Variant velger visuell (Produkt info side)
-----------------------------------------------------------------------------*/
.royalSliderVariant {
    height: 77px;
    width: calc(#{67px * 3} + 20px);
    padding: 10px 40px;
    box-sizing: content-box;
    background: transparent;

    @include toSizeSM {
        height: 73px;
        width: calc(#{63px * 3} + 20px);
        margin: auto;
    }
}
.royalSliderVariant .rsOverflow,
.royalSliderVariant .rsSlide {
    background: transparent;
}
.royalSliderVariant > .variant-slider-row {
    display: none;
}
.royalSliderVariant.rsHor .rsArrowLeft .rsArrowIcn {
    background: url(/css/images/prev-horizontal.png) no-repeat;
    background-position: center center;
}
.royalSliderVariant.rsHor .rsArrowRight .rsArrowIcn {
    background: url(/css/images/next-horizontal.png) no-repeat;
    background-position: center center;
}
.royalSliderVariant .rsArrow {
    width: 40px;
}

.royalSliderVariant .VariantChildVisual {
    border: 1px solid #ccc;
    margin-right: 10px;
    display: inline-block;
    float: left;

    &:last-child {
        margin-right: 0;
    }

    @include toSizeSM {
        img {
            width: 61px;
            height: 71px;
        }
    }
}

.royalSliderVariant .VariantChildVisualSelected {
    border: 1px solid #2a2a2e;
}

.royalSliderVariant .VariantChildVisualDisabled {
    background-color: #bbb;

    img {
        opacity: 0.5;
    }
}

.ProductInfo .VariantChildAttribName {
    display: none;
}

.VariantChildTwo,
.only-attribute-one .VariantChildOne,
.only-attribute-two .VariantChildTwo {
    font-weight: bold;
    font-size: 14px;
    padding: 1px 10px;
    margin-right: 10px;
    color: #333335;
    border: 1px solid #d0d0d2;
    margin-bottom: 10px;
    display: inline-block;

    &.VariantChildVisualSelected {
        border-color: #2a2a2e;
        border-width: 1px;
    }
}

.VariantChildVisualDisabled.VariantChildTwo {
    background-color: #ccc;
}

.body-product-info .qtip-warning {
    display: block;
    font-size: 14px;
    color: white;
}

.body-product-info .qtip-warning:first-letter {
    text-transform: uppercase;
}

.body-product-info .qtip-title {
    font-size: 14px;
}

#variant-visual-warning-title,
#variant-visual-warning {
    display: none;
}

.label-attribute1,
.label-attribute2 {
    display: none;
    font-size: 14px;
}

.label-attribute2 {
    margin-bottom: 10px;
}

.VariantDDL1,
.VariantDDL2 {
    margin-bottom: 10px;
}

.variant-container,
.variant-container .only-attribute-one,
.variant-container .only-attribute-two {
    select,
    .VariantDDL1,
    .VariantDDL2 {
        height: 38px;
        box-sizing: border-box;
        border: 1px solid #cccccc;
        padding: 0px;
        padding-left: 5px;
        border-radius: 3px;
        margin-right: 10px;

        @include toSizeSM() {
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0;
        }
    }
}

.variant-container select {
    margin-bottom: 2px;
}

.variant-container .only-attribute-one .label-attribute1 {
    display: block;
    margin: 20px 0 5px 0;
    color: #525252;
}

div.variant-slider + div.hidden-variant-buy-button{
    bottom: 0px;
}





.slide-as-btn {

    &.royalSliderVariant .VariantChildVisual {
        padding: 5px 10px;
        border-radius: 4px;
        background-color: #fff;
        min-width: 90px;
        max-width: 90px;
        margin-bottom: 8px;
    }

    @media screen and (min-width: 321px) {
        &.royalSliderVariant .VariantChildVisual {
            min-width: 100px;
            max-width: 100px;
        }
    }

    @media screen and (min-width: 769px) {
        &.royalSliderVariant .VariantChildVisual {
            min-width: 110px;
            max-width: 0;
        }
    }

    &.royalSliderVariant .VariantChildVisual.VariantChildVisualSelected {
        border: 1px solid #666;
    }


    &.royalSliderVariant .rsSlide {
        left: 0px !important;
        display: inline-block;
        position: relative;
        overflow: visible;
        text-align: center;
        float: left;
        width: 100%;
    }


    &.royalSliderVariant .rsContainer {
        transform: none !important;
    }

    &.royalSliderVariant .rsOverflow {
        overflow: visible;
        height: auto !important;
    }

    &.royalSliderVariant {
        height: auto;
        padding: 5px;
        width: 100%;
        display: inline-block;
    }

    &.royalSliderVariant.rsDefault .rsArrow {
        display: none !important;
    }

    .grab-cursor {
        cursor: default;
    }
}

.ProductInfo .royalSliderVariant.slide-as-btn .VariantChildAttribName {
    display: block;
}


