﻿body.mc-inline-editor .smart-label .inactive label,
body.mc-inline-editor .smart-label .active label {
	display: block !important;
}

[mc-inline] {
	outline: orange dotted 2px;
	cursor: pointer;
}

span[mc-inline] {
	min-width: 10px;
	min-height: 10px;
	display: inline-block;
}

.show-inline-placeholder-info {
	font-size: 0.7em;
	font-weight: bold;
	color: #333;
	text-shadow: none;
}

.SearchInfo [mc-inline] {
	display: table-footer-group;
}

.mc-res-info-h {
	font-weight: bold;
	margin: 7px 0 3px 0;
	border-bottom: 1px solid #999;
}

.mc-qtip {
	min-width: 252px;
}

.mc-qtip .mc-res-data {
	border: none;
	background-color: transparent;
	color: #aaa;
}

.resource-culture {
	font-size: 9px;
	font-weight: bold;
	color: green;
	line-height: 15px;
	padding-top: 5px;
	margin: 0 30px -15px 0;
	float: right;
	position: relative;
}

.resource-default {
	font-size: 9px;
	font-weight: bold;
	color: orange;
	line-height: 15px;
	padding-top: 26px;
	margin: 0 -10px -36px 0;
	float: right;
	position: relative;
}

.inline-input {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	width: 90%;
}

.inline-btn-content {
	text-align: center;
	margin-top: 9px;
	width: 100%;
}

.inline-btn {
	width: 50%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.inline-glyphicon {
	display: inline-block;
	float: right;
	margin: 11px -3px 0 0;
	cursor: pointer;
}
