﻿@import 'variables';
@import 'mixins';

/**** Favorites ****/
.favorite-header-article {
    width: 100%;
    padding: 15px;

    .checkbox label {
        padding-left: 0;
    }

    @include fromSizeSM() {
        padding: 15px;
    }

    #favourite-selector-container {
        display: inline-block;
    }

    .btn.btn-default.add-favorite-list {
        .glyphicon {
            margin-right: 0.7em;
        }
    }
}

.favorite-tools {
    width: 100%;
    position: relative;

    .btn {
        border-radius: 0px;
    }

    .heart-full {
        display: none;
    }

    label {
        margin-right: 2em;
        input[type=checkbox] {
            display: none;
        }
        input[type=checkbox] + span:before {
            content: '';
            width: 18px;
            height: 18px;
            display: inline-block;
            margin: 0px 10px 0px 0px;
            border: 1px solid #c6c6c6;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            font-size: 16px;
            vertical-align: top;
            visibility: visible;
        }

        input[type=checkbox]:checked + span:before {
            content: "\e013";
            color: #333;
        }
    }
}

.WebPubElement .buy-favorites,
.WebPubElement .remove-favorites {
    color: #fff;
    background-color: #39c;
    border-color: #39c;
    font-weight: 300;
    font-family: Yanone Kaffeesatz,sans-serif;
    background: #39c;
    color: #fff;
    font-size: 24px;
    line-height: 1.4em;

    .buy-favorites-text {
        display: none;
    }

    @include fromSizeSM() {
        padding: 1px 22px;

        .buy-favorites-text {
            display: inline-block;
            font-size: 20px;
            line-height: 1em;
        }
    }
}

.fav-chk-content {
    padding: 10px 40px;

    @include fromSizeSM() {
        padding: 0 15px;
    }
}

.favorite-header-article {
    @include fromSizeSM() {

        .new-favorite-container {
            position: relative;
            height: 90px;

            button {
                position: absolute;
                bottom: 0px;
                right: 15px;
            }
        }
    }
}

.new-favorite-container .btn .glyphicon {
    margin-right: 0.5em;
}

.WebPubElement .remove-favorites {
    background-color: #fff;
    border-color: #ccc;
    color: #ccc;
    width: 50%;

    @include fromSizeSM() {
        width: 30%;
    }

    .remove-favorites-text {
        display: none;
    }
}

@include fromSizeLG {
    .favorite-content .WebPubElement:hover .fav-remove-button {
        display: block;
    }
}

button.buy-favorites {
    border-radius: 0 4px 4px 0;
    width: 50%;

    @include fromSizeSM() {
        width: auto;
        margin-right: 0;
    }
}

.favorite-buy-disable button.remove-favorites {
    border-radius: 4px;
}

button.remove-favorites {
    border-radius: 4px 0 0 4px;

    @include fromSizeSM() {
        width: auto;
    }
}

@include fromSizeSM() {
    .favorite-buttons {
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 20px;
    }
}

.favorite-content {

    .check-favorite {
        display: block;
    }

    .favorite-button {
        display: none !important;
    }
}
/* favorite small cart*/
.btn-fav-cart {
    padding: 0px 2px;
    font-size: 27px;
    margin-bottom: -7px;

    &.btn-link {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    @include fromSizeSM() {
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 35px;
    }
}

@include fromSizeSM() {
    i.glyphicon.glyphicon-heart-empty {
        top: 2px;
    }

    i.glyphicon.glyphicon-heart.heart-full {
        top: 2px;
    }

    .cart-small-fav {
        i.glyphicon.glyphicon-heart-empty {
            top: 0px;
        }

        i.glyphicon.glyphicon-heart.heart-full {
            top: 0px;
        }
    }
}

.fav-cart-text {
    top: 11px;
    margin-left: -24px;
    position: absolute;
    font-size: 13px;
    color: #fff;
    width: 24px;
    overflow: hidden;

    @include fromSizeSM() {
        position: absolute;
        left: 60px;
        top: 9px;
        text-align: center;
        background: white;
        border: 2px #3399cc solid;
        width: 24px;
        border-radius: 50%;
        color: black;
        font-size: 14px;
        font-weight: bold;
    }
}

.mc-inline-editor .hidden.fav-inline-info {
    display: block !important;

    .show-inline-placeholder-info {
        display: none;
    }
}

.check-favorite-config {
    min-height: 14px;
    display: block;
}

.pub-favorites .has-variant-img {

    .variant-slider .rsThumbs {
        display: none;
    }

    .ad-buy-button,
    .fav-remove-button {
        display: block;
    }
}

.exists-in-cart {
    display: none;
}

.pub-favorites {
    .d4-listing-row {
        .check-favorite {
            display: inline-block;
            margin: 0;
            margin-right: 10px;
        }

        .ProduktImg {
            display: inline-block;
        }

        .ProduktImg img {
            max-width: 80px;
        }
    }
}

.pub-favorites.highlight {
    background-color: #dceadb;

    .exists-in-cart {
        display: block;
    }
}

.btn-favorite:active,
.btn-favorite:focus,
.btn-favorite:hover {
    box-shadow: none;
}
/*Not in use anymore? */
#favourites-add-new-list-container {
    h3 {
        margin-bottom: 25px;
    }

    input[type="text"], button {
        width: 100%;
        margin-bottom: 5px;
    }
}
/*Not in use anymore? END*/

