﻿@import 'mixins';

.matrix-popup {

    #product-variant-matrix {
        padding-top: 20px;

        .rs-table {
            margin-top: 0;
            width: 100%;
        }

        tr {
            background-image: none;
        }
    }

    input[type="text"] {
        border-radius: 0px;
        border: 1px solid #ccc;
    }

    .btn-group.bottom {
        margin-bottom: 20px;
    }

    .previous-product .btn-default,
    .next-product .btn-default {
        background-color: rgb(240, 240, 240);
    }

    @include toSizeSM {
        .modal-content {
            border: none;
        }

        &#center-popup .lightbox-close {
            display: none;
        }

        #product-variant-matrix {
            padding-top: 48px;

            .rs-table {
                border: none;

                td.lc-r-col2:before {
                    content: '';
                }
            }

            tr {
                padding: 4px 5px;
                border-left: none;
                border-right: none;
                border-top: none;
            }

            input[type="text"] {
                width: 100px;
                height: 40px;
                font-size: 14px;
                margin-bottom: 15px;
            }

            .btn-group.bottom {
                display: none;
            }
        }

        .btn-group.top {
            position: fixed;
            transform: translate3d(0px,0px,0px); /* Fix iphone position:fixed */
            margin-bottom: 1em;
            z-index: 9;
            width: 100%;
            background: #fff;
            border-bottom: 1px solid #999;
            top: -1px;
            right: 0;
            padding: 7px;
            text-align: right;

            &.btn-group > .btn {
                float: none;
            }

            &.btn-group > .btn:first-child {
                margin-right: -2px;
            }
        }

        fieldset.col-sm-12 {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

a.variant-matrix-button {
    cursor: pointer;
}

.buy-info-container a#variant-matrix-button {
    padding: 10px 0;
    margin: 15px 0;
    display: inline-block;
    text-align: center;
    width: 100%;
    border-color: #888;
    font-weight: 300;
    font-family: Yanone Kaffeesatz,sans-serif;
    background: #888;
    color: #fff;
    border-radius: 0px;
    cursor: pointer;

    &:before {
        content: '\e818';
        font-family: "multicase-icons";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: 5px;
        text-align: center;
        font-variant: normal;
        text-transform: none;
    }

    &:hover, &:focus {
        text-decoration: none;
    }

    @include fromSizeSM {
        width: 50%;
        min-width: 180px;
        height: 35px;
        line-height: 13px;
    }

    @include fromSizeMD {
        margin-left: 15px;
        max-width: 170px;
        min-width: 170px;
    }
}

.modal-dialog.modal-lg {
    max-height: 100%;
}

.modal-body {
    @include fromSizeSM {
        max-height: 80vh;
        overflow-y: auto;
    }
}

.hide-buy-button-for-zero-amount .hide-when-buy-selected {
    display: none;
}

.show-when-buy-selected {
    display: none;
}

.hide-buy-button-for-zero-amount .show-when-buy-selected {
    display: block;
}

.product-variant-matrix-modal {
    .btn-group.pull-right.bottom {
        @include toSizeSM {
            display: block !important;
        }
    }

    .variant-matrix-modal {
        margin: 15px 0 15px 0;

        button.btn.btn-primary {
            height: 35px;
            line-height: 13px;
            min-width: 180px;
            display: inline-block;
            text-align: center;
            width: 100%;
            border-color: #888;
            font-weight: 300;
            font-family: Yanone Kaffeesatz, sans-serif;
            background: #888;
            color: #fff;
            border-radius: 0px;
            cursor: pointer;
        }

        .modal-header {
            display: flex;
        }

        .modal-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 10%);

            @include fromSizeSM {
                width: 1170px;
            }
        }

        .modal-header .close {
            padding: 1rem;
            margin: -1rem -1rem -1rem auto;
        }
    }

    .variant-table {

        .variant-table-container {
            overflow-x: auto;
        }

        tr {
            border-bottom: 1px solid #d4e0ec;
        }

        th:first-child {
            min-width: 10em;
        }

        .matrix-quantity {
            text-align: left;
            margin-left: 20px;

            .DynamicStockTooltipContainer {
                height: auto;
                width: 100%;
            }

            .qty-control {
                height: 25px;
                width: 100%;
            }

            input {
                margin-right: 0.5em;
                min-width: 6em;
                border: 1px solid #d4e0ec;
                font-size: 10px;
                height: 44px;
                padding: 2px;

                @include toSizeSM {
                    min-width: 5em;
                }
            }
        }

        .in-stock, .not-in-stock, .not-in-stock-uncomfirmed {
            min-width: 4.3em;
            border-bottom: 4px solid;
            display: inline-block;

            @include toSizeSM {
                min-width: 5em;
            }
        }

        .in-stock {
            border-color: green;
        }

        .not-in-stock {
            border-color: red;
        }

        .not-in-stock-uncomfirmed {
            border-color: darkorange;
        }

        .DynamicStockTooltipContainer {
            display: none;
        }

        .qty-control.api-qty {
            & + .DynamicStockTooltipContainer {
                display: inline-block;
            }
        }

        .variant-header {

            img {
                width: 64px;
                margin: 10px;
                float: left;
                height: auto;
            }

            .variant-header-text {
                float: left;
                margin: 20px 0;

                @include toSizeSM {
                    font-size: 12px;
                }
            }
        }

        .variant-main {
            width: 100%;
            padding-top: 10px;
            height: 4em;
            border-bottom: 1px solid #d4e0ec;

            @include toSizeMD {
                min-height: 9em;
            }

            .variant-left {
                display: block;
                text-align: left;
                float: left;

                @include toSizeSM {
                    display: contents;
                }

                > div {
                    margin: 0;
                    display: inline-block;
                    padding: 0 10px;
                }

                input {
                    max-width: 9em;
                    padding-left: 5px;
                    margin-right: 10px;
                    border: 1px solid #d4e0ec;

                    @include toSizeSM {
                        max-width: 7em;
                    }
                }

                span {
                    font-weight: bold;
                    margin-right: 2px;
                }
            }

            .variant-right {
                display: block;
                float: right;

                @include toSizeSM {
                    float: none;
                    margin-top: 1em;
                }

                .cart-small-icon {
                    width: 100%;
                }

                > div {
                    margin: 0;
                    display: inline-block;
                    padding: 0 10px;
                }
            }
        }

        .variant-header-attribute-text {
            padding-top: 15px;
            height: 50px;
            margin-left: 20px;
            text-align: left;

            span {
                font-size: 16px;
                font-weight: normal;
            }
        }
    }
}
