﻿@import 'variables';
@import 'mixins';

$borderColor: #d4e0ec;
$borderPopupColor: #39c;
$favBackgroundColor: transparent;
$favColor: #39c;



#rhs-popup{
    position:fixed;
    right:0px;
    height:100vh;
    background-color:white;
    @include fromSizeSM {
        width:530px;
    }
    @include toSizeSM{
        width:480px;
        max-width:95%;
    }
  

    z-index:9000;
    border-left:1px solid $borderColor;
}

#rhs-popup .slideover-content .container{
    @include fromSizeSM {
        width: 500px;
    }

    @include toSizeSM {
        width: 450px;
    }

    @include fromSizeLG {
        width: 494px;
    }
}


.slideover-box .slideover-content{
    margin:10px;
    height:100vh;
    overflow-y:scroll;
}

#rhs-popup .lightbox-close {
    width: 1.2em;
    height: 1.2em;
    margin-top: -0.5em;
    margin-right: -1.0em;    
    background: url("/css/Master/close.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    font-size: 2.5em;
    cursor: pointer;
    text-align: right;
    z-index: 99999;
    left: -15px;
    position: absolute;
}
#rhs-popup .lightbox-open {
    width: 1.2em;
    height: 1.2em;
    margin-top: -0.5em;
    margin-right: -1.0em;
    background: url("/css/Master/close.png") no-repeat scroll 0 0;
    font-size: 2.5em;
    cursor: pointer;
    text-align: right;
    z-index: 99999;
    left: -15px;
    position: absolute;
}

#rhs-popup.shrinking-box {

    @include fromSizeSM {
        width: 400px;
    }

    @include toSizeSM {
        width: 450px;
    }

    @include fromSizeLG {
        width: 400px;
    }

    position: absolute;
    top: 0;
    right: 0; /* Modified this line */
    height: 100vh;
    background-image: url('/App_Themes/Master/Images/1px_transparent.png');
    background-position: right; /* EDIT: This property needs to be set */
}