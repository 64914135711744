﻿@import 'variables';
@import 'mixins';

$borderColor: #d4e0ec;
$borderPopupColor: #39c;
$favBackgroundColor: transparent;
$favColor: #39c;



#tiny-popup {
    position: fixed;
    right: 0px;
    max-height: 40vh;
    background-color: white;

    @include fromSizeSM {
        width: 300px;
    }

    @include toSizeSM {
        width: 480px;
        max-width: 95%;
    }

    z-index: 9100;
    box-shadow: 0px 0px 100px 14000px rgba(0,0,0,0.8);
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

#tiny-popup .tiny-popup-content .container{
    @include fromSizeSM {
        width: 400px;
    }

    @include toSizeSM {
        width: 450px;
    }

    @include fromSizeLG {
        width: 400px;
    }
}


.tiny-popup .tiny-popup-content {
    margin: 10px;
}

#tiny-popup .lightbox-close {
    width: 1.2em;
    height: 1.2em;
    margin-top: -1.0em;
    margin-right: -1.0em;
    background: url("/css/Master/close.png") no-repeat scroll 0 0 #000000;
    background: url("/css/Master/close.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    font-size: 2.5em;
    cursor: pointer;
    text-align: right;
    z-index: 99999;
    right: 0px;
    position: absolute;
}

#tiny-popup {
    .glyphicon-calendar.large-icon {
        font-size: 82px;
    }

    .btn-group {
        margin-top: 15px;
        width: 100%;

        .btn {
            width: 50%;
        }
    }
}