﻿@import 'variables';
@import 'mixins';

.is-config #section_c_1 {
    display: block !important;
    height: auto !important;
}

.config-description {
    margin-top: 15px;
    margin-bottom: 20px;
}

img.selected-item-image {
    max-height: 140px;
}

.config-items {
    padding: 0;
    margin-bottom: 20px;

    @include fromSizeSM {
        padding: 0 15px;
        margin-bottom: 0;
    }
}

.config-items .config-stock {
    @include fromSizeMD {
        margin-right: 42px;
        margin-top: -27px;
    }

    @include fromSizeLG {
        margin-right: 74px;
    }
}

.config-items-head {
    text-align: center;
    font-weight: bold;
}

.selected-item-noimages {
    margin: 56px 0;
    font-size: 20px;
    font-weight: bold;
}

.config-items .controls {
    padding-top: 5px;
    padding-bottom: 5px;
}

.ProductInfo .config-item-stock {
    img {
        width: 18px;
        margin-left: -8px;
    }

    @include fromSizeSM {
        text-align: center;

        img {
            display: none;
        }

        .StockIcon {
            margin-right: 0;
        }
    }
}

.config-item-price {
    font-weight: bold;
    text-align: right;
}

.variant-group,
.variant-list {
    @include toSizeSM {
        padding: 0;
    }
}

/*.variant-group .config-item .radio-inline {
    visibility: hidden;
}*/

.config-group-header span {
    background-color: #f9f9f9;
    padding: 5px 10px;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    display: inline-block;
    font-size: 18px;
}

.summary-content {
    margin: 20px 0 50px 0;
    border-top: 1px solid #ccc;
    padding-top: 15px;

    @include toSizeSM {
        border-top: 1px solid #f9f9f9;
    }
}

.config-summary-header {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
}

.config-price {
    font-size: 26px;
    font-weight: bold;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 15px;
}

.scoll-config-summary {
    float: right;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 15px;
    font-size: 20px;
    line-height: 30px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding: 1px 20px;

    @include fromSizeMD {
        min-width: 180px;
    }

    i {
        /*float: right; Float right feiler i firefox (mobil)*/
    }
}

@include toSizeSM() {
    .selected-item {
        text-align: center;
    }

    .ProductInfo.is-config .section-menu {
        display: block;

        .section-menu-item {
            display: none;
        }

        .scoll-config-summary {
            position: relative;
            width: calc(100% - 58px);
        }
    }
}

.config-info {
    padding-left: 0;
    padding-right: 0;
}

.config-items button {

    &.icon-info-btn {
        box-shadow: none;
        background-color: #fff;
        border: none;
        padding-left: 0;
        padding-right: 0;
        margin-top: 3px;

        &:focus {
            outline-width: 0;
        }
    }

    &.icon-info-btn i {
        position: absolute;
        color: #777;
    }

    &.icon-info-btn[aria-expanded='false'] i.icon-up-open {
        visibility: visible;
    }

    &.icon-info-btn[aria-expanded='true'] i.icon-up-open {
        visibility: hidden;
    }

    &.icon-info-btn i.icon-down-open {
        visibility: hidden;
    }

    &.icon-info-btn[aria-expanded='true'] i.icon-down-open {
        visibility: visible;
    }

    .info-link {
        font-size: 14px;
        float: left;
        padding: 2px 5px 0 0;
    }
}

.config-item-content {
    background: #f9f9f9;
    padding: 15px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

a.config-item-link {
    display: block;
    padding: 10px 0;
    text-decoration: underline;
}

.variant-dropdown1,
.variant-dropdown2 {
    position: relative;
    padding-left: 0;
    padding-bottom: 5px;

    @include toSizeSM() {
        padding: 0;
    }

    select {
        appearance: none;
        position: relative;
        z-index: 1;
        background: transparent;
        padding: 10px 35px 10px 10px;
        font-size: 16px;
        border: none;
        border-top: 3px solid #39c;
        font-weight: bold;
        width: 100%;

        + i {
            position: absolute;
            top: 9px;
            right: 15px;
            font-size: 18px;
        }

        &:focus {
            appearance: none;
            border-left: none;
            border-right: none;
            border-bottom: none;
        }
    }
}
/*.variant-group {
    border: 1px solid #ccc;
    display: block;
    padding: 50px 0px 50px 18px;
    margin: -50px -15px 0px -15px;
    background-color: #f9f9f9;
}*/
/*target Internet Explorer 9 and Internet Explorer 10:*/
@media screen and (min-width:0\0) {
    select.config-variant {
        background: none;
    }
}
/*.config-items */
/* HTML5 Boilerplate accessible hidden styles */
.config-items .radio-inline {
    padding-left: 10px;
}

.radio-inline input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
/* One radio button per line */
.radio-inline label {
    display: block;
    cursor: pointer;
    line-height: 2.5;
    font-size: 1.5em;
}

.radio-inline input[type="radio"] + span {
    display: inline-flex;
}
/* the basic, unchecked style */
.radio-inline input[type="radio"] + span:before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    border: 2px solid #fff;
    box-shadow: 0 0 0 1px #999;
    margin-right: 0.75em;
    transition: 0.5s ease all;
}
/* the checked style using the :checked pseudo class */
.radio-inline input[type="radio"]:checked + span:before {
    background-color: #666;
    box-shadow: 0 0 0 1px #999;
    transition: background 0.25s linear;
}

.config-buying .buy-product {
    min-width: 230px;
    float: right;
}

.config-item .smart-label {
    .reg-textbox {
        padding-top: 12px;
        padding-bottom: 0;
        background-color: #FDFEDB;
        font-weight: bold;
    }

    i.icon-star.reg-icon {
        float: right;
        margin-top: -22px;
        margin-right: 5px;
    }
}
