﻿@import 'mixins';

/* =Search filter Attributter
-----------------------------------------------------------------------------*/
.RemoveAllSelectedAttributes .ShowHideFilter,
.RemoveAllSelectedAttributes .ClearFilter,
.FilterListTitle {
    display: none;
}

.SearchFilterAttributes {
    margin-bottom: 10px;
    padding-bottom: 15px;
    width: 100%;        
    
    
    @include fromSizeSM() {
        border: none;
        background:none;        
        margin: 0;
        padding-bottom: 0px;
    }
}

.body-menu-center .SearchFilterAttributes{
    border-top: none;
}

.SearchFilterAttributes .DeleteButton,
.SearchFilterAttributes .SelectedAttribute a {
    background-image: inline('images/filter_delete.png');
}

.SearchFilterAttributes .DeleteButton {
    background-repeat: no-repeat;
    background-position: 0 4px;
    margin-right: 0px;
    padding-left: 9px;
}

.SearchFilterAttributes .SelectedAttribute {
    margin-right: 19px;
}

.attribute-left-box .SelectedAttribute {
    margin-top: 5px;
}

.SearchFilterAttributes .SelectedAttribute a {
    background-repeat: no-repeat;
    background-position: 0 4px;
    text-decoration: none;
    color: #666666;
    padding-left: 9px;
}

.SearchFilterAttributes .SelectedAttribute a:hover {
    text-decoration: underline;
    border-left: 1px solid #999999;
}

.list-type-container {
    padding: 0 5px 0 10px;
    @include fromSizeSM{
        float: right;
        padding:0;
    }
}

.list-type-label {
    display: none;

    @include fromSizeSM {
        display: inline-block;
        padding-right: 5px;
        padding-top: 6px;
    }
}

.list-type-container .ListButton,
.list-type-container .GridButton {
    float: right;
    cursor: pointer;
    width: 50%;

    @include fromSizeSM {
        width: auto;
    }
}

.list-type-button {
    border: 1px solid #ccc;
    font-size: 24px;
    color: #555;
    width: 100%;
    text-align: center;

    @include fromSizeSM {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 25px;
        width: 31px;
    }
}

.list-type-button.ProductListingTypeSelected {
    display: none;
}

.attribute-sort-box.produkt-filter-attributter {
    display: block;
    padding: 5px 5px 2px 5px;
    @include fromSizeSM {
        display: block;
    }
}


/* Checkboxes */
.SearchFilterAttributes .CheckboxList {
    height: 29px;
    margin-bottom: 10px;
}

.hide-no-stock-container,
.group-variants-container {
    display: inline-block;
}

.VariantContainer2 {
    margin-right: 20px;
}

.VariantContainer2,
.LagerStatusContainer2 {
    display: inline-block;

    @include fromSizeSM {
        margin-top: 10px;
    }

    label {
        font-weight: normal;
        margin: 0;
        margin-left: 5px;
        cursor: pointer;
    }

    input {
        margin: 0;
        position: relative;
        top: 1px;
        cursor: pointer;
    }
}


.SearchFilterAttributes .RemoveAllSelectedAttributes a {
    text-decoration: none;
    color: #666666;
}

.SearchFilterAttributes .RemoveAllSelectedAttributes a:hover {
    text-decoration: none;
}

.SearchFilterAttributes .SelectedAttributeBox {
    font-weight: bold;
    margin-bottom: 15px;
}

.SearchFilterAttributes .SearchInfo {
    @include fromSizeSM {
        padding: 10px 0;
        font-weight: bold;
    }
}

.SearchFilterAttributes .SearchText {
    @include toSizeSM {
        padding: 5px 0;
        div {
            display:none;
        }
    }
}

.SearchFilterAttributes .SearchInfo .SearchText div:first-child {
    width: 86px;
    float: left;
}

.SearchFilterAttributes .SearchInfo .Filter {
    float: right;
    display: none;
}

.SearchFilterAttributes .FilterListTitle {
    margin: 5px 0px 5px 0px;
    color: #666666;
    font-weight: bold;
}

.SelectedAttributeBox a {
    cursor: pointer;
}

.SearchFilterAttributes .AttributeBox a.toggle:hover {
    text-decoration: none;
}

.SearchFilterAttributes .AttributeBox a.toggle {
    background-image: inline('images/filter_arrow_down.png');
    background-repeat: no-repeat;
    display: block;
    float: right;
    width: 11px;
}

.SearchFilterAttributes .AttributeBox a.toggle.hide {
    background-image: inline('images/filter_arrow_up.png');
    background-repeat: no-repeat;
    display: block;
    float: right;
    width: 11px;
}

.SearchFilterMoreButton {
    background: inline('images/filter_more_button.png') no-repeat scroll 0 0 transparent;
    height: 13px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-top: 0;
    position: relative;
    top: -5px;
    width: 122px;
    float: right;
}

.SearchFilterMoreButton a.ShowMore {
    color: #666666;
    font-size: 7pt;
    display: block;
    padding-left: 28px;
    text-decoration: none;
    font-weight: bold;
    padding-top: 1px;
    width: 85px;
    height: 13px;
    letter-spacing: 1px;
}

.SearchFilterMoreButton a:hover {
    text-decoration: none;
}

.SearchFilterMoreButton span.ShowMore {
    background-color: #c7532c;
    color: white;
    height: 13px;
    margin-top: 0;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: block;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    padding-bottom: 2px;
}

.attribute-left-box .SearchFilterMoreButton {
    background-image: none;
    top: inherit;
}

.SearchFilterAttributes #AttributeListBox {
    width: 100%;
    padding: 15px 10px 0 10px;

    @include fromSizeSM {
        padding: 0;
    }
}

.SearchFilterAttributes .AttributeBox {
    text-align: left;
    border-bottom: 1px solid #d8d6ce;
    padding: 5px;
    background-color: #ffffff;
    overflow: hidden;
    min-height: 26px;
}

.attribute-left-box .AttributeBox {
    background-color: inherit;
}

.SearchFilterAttributes .AttributeBoxWebAvansert {
    height: 0px;
    min-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-width: 0px;
    opacity: 0;
}

.SearchFilterAttributes .AttributeBoxWebAvansertShow {
    height: 0px;
    min-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-width: 0px;
    opacity: 0;
}

.SearchFilterAttributes .AttributeShow {
    height: 0px;
    min-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-width: 0px;
    opacity: 0;
}

.SearchFilterAttributes .AttributeStayHidden {
    display: none;
}

.SearchFilterAttributes .AttributeBox a {
    text-decoration: none;
    color: #333333;
    padding-left: 0px;
    cursor: pointer;
}

.SearchFilterAttributes .AttributeBox .selected {
    font-weight: bold;
}

.SearchFilterAttributes .AttributeBox .disabled {
    opacity: 0.4;
    cursor: text;
    text-decoration: none;
}

.SearchFilterAttributes .AttributeBox .AttributeValueShow {
    opacity: 0.4;
    cursor: pointer;
    text-decoration: none;
}

.SearchFilterAttributes .AttributeBox .AttributeValueHide {
    opacity: 1.0;
    cursor: pointer;
    text-decoration: none;
}

.SearchFilterAttributes .AttributeBox a.AttributeValueShow:hover {
    text-decoration: none;
}

.SearchFilterAttributes .AttributeBox a.AttributeValueHide:hover {
    text-decoration: none;
}

.SearchFilterAttributes .AttributeBox a:hover {
    text-decoration: underline;
}

.SearchFilterAttributes .AttributeBox a.disabled:hover {
    text-decoration: none;
}


.SearchFilterAttributes .AttributeBox div:first-child {
    width: 65px;
    float: left;
    margin-right: 10px;
    font-weight: bold;
}

.SearchFilterAttributes .AttributeBox .AttributBoxTitle {
    width: 75px;
    color: #333333;
}
.SearchFilterAttributes .AttributeBox .collapse-attributt .AttributeTitle {
    display: inline-block;
}

.SearchFilterAttributes .AttributeBox .collapse .attribute-value,
.SearchFilterAttributes .AttributeBox .collapsing .attribute-value {
    font-weight: normal;
}

.SearchFilterAttributes .AttributeBox .collapse-attributt .glyphicon {
    right: 25px;
}

.SearchFilterAttributes .AttributeBox .collapse,
.SearchFilterAttributes .AttributeBox .collapsing{
    width: 100%;
}

.SearchFilterAttributes .AttributeBox div {
    width: 130px;
    float: left;
    padding-right: 5px;
    white-space: nowrap;
}

.SearchFilterAttributes .AttributeBox div span {
    color: #aaaaaa;
}

.SearchFilterAttributes .AttributeBox .AttributeTitle {
    color: #333333;
}

.SearchFilterAttributes .AttributeBox .ArrowDown {
    display: block;
    float: right;
    padding: 0px;
    margin-top: 6px;
    background-image: none;
}

.SearchFilterAttributes .ShareBox {
    margin: 0px 0px 0px 0px;
    position: relative;
    left: 0px;
    top: -5px;
    width: 340px;
}

.SearchFilterAttributes .SearchString {
    width: 94%;
    height: 25px;
    padding-left: 25px;
    padding-right: 5px;
    background: inline('images/LinkIcon.png') no-repeat 5px 5px #ffffff;
    border: 1px solid #999999;
}

.SearchFilterAttributes .AttributeDDLBox {
    width: 100%;
}

.SearchFilterAttributes {

    .AttributeDropDown {
        width: 100%;
        height: 38px;
        box-sizing: border-box;
        border: 1px solid #cccccc;
        padding: 0px;
        padding-left: 5px;
        border-radius: 3px;
        margin-right: 10px;
        margin-bottom: 15px;        
    }
    select {
        font-size: 16px;
    }

    @include fromSizeSM {
        .AttributeDropDown{
            width: calc(25% - 10.4px);
            height: 32px;
            margin-bottom: 10px;
        }
        select {
            font-size: 14px;
        }
    }        
}

.SearchFilterAttributes .AttributesDDLContainer > *:nth-child(4n) {
    margin-right: 0;
}

.SearchFilterAttributes .attribute-remove-lbl {
    display: inline-block;
    color: #c7532c;
    width: 100%;
    height: 32px;
    text-decoration: none;
    border: 1px solid #cccccc;
    background-color: #efefef;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 4px 10px;
    line-height: 23px;
    position: relative;

    &::after {
        content: "\e014";
        font-family: 'Glyphicons Halflings';
        position: absolute;
        top: 5px;
        right: 10px;
    }

    &:hover {
        background: #f6f6f6;
    }

    @include fromSizeSM {
        width: calc(25% - 10.25px);
    }
}

.AttributesDDLContainer {
    @include toSizeSM {
        padding-top: 10px;
    }
}


.SearchFilterAttributes .AttributeDropDown .AdvancedDropDown {
    display: none;
}

.SearchFilterAttributes .AttributeHasBeenSelected {
    border: solid #C7532C 1px;
}

.SearchFilterAttributes .SearchString {
    width: 150px;
    margin-top: 15px;
}

#slider-range {
    width: 150px;
}

.SearchFilterAttributes .AttributeBox div.ui-slider-range-min {
    width: 40px;
    display: inline-block;
}

#slider-range-min, #slider-range-max {
    width: 40px;
    display: inline-block;
}

.attribute-left-box {
    margin: 0px 0px 0px 0px;
}

.attribute-left-box .SearchFilterAttributes {
    padding: 0px;
}

.attribute-left-box .FilterListTitle {
    display: none;
}

.attribute-left-box .attribute-sort-box {
    display: none;
}

.attribute-left-box .CheckboxList {
    float: none;
    margin-top: 5px;
}

.attribute-left-box .SearchInfo {
    display: none;
}

.attribute-left-box .RemoveAllSelectedAttributes {
    float: none;
    height: 15px;
}

.attribute-left-box .ShareBox {
    clear: left;
}

.attribute-left-box .SearchString {
    width: 143px;
}

.attribute-left-box #AttributeListBox a {
    cursor: pointer;
}

.attribute-left-box #AttributeListBox .AttributeBox a.disabled:hover {
    cursor: auto;
}

.filter-option-wrap {
    width: 100%;
    padding: 0 15px 0 5px;
}


#filterlink {
    margin-top: -37px;
    font-size: 16px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 0;
    width: 50%;

    &.btn-default:active,
    &.btn-default:hover,
    &.btn-default:focus,
    &.btn-default:visited {
        background-color: transparent;
        box-shadow: none;
    }

    @include fromSizeSM {
        display: none;
    }
}

#filteroptions {
    @include fromSizeSM {
        &.collapse {
            display: block !important;
        }        
    }    
}

.filter-options[aria-expanded="false"] .icon-up-open,
.filter-options[aria-expanded="true"] .icon-down-open {
    display: none;
}

.filter-options[aria-expanded="true"] .icon-up-open,
.filter-options[aria-expanded="false"] .icon-down-open {
    display: inline-block;
    font-size: 14px;display: inline-block;
    font-size: 14px;
}

.ProdListContainer .TableList {
    margin-top: -5px;
    width:100%;
}
