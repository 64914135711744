﻿@import 'variables';
@import 'mixins';


.dep-id-content .input-group .form-control.input-dep-id-code {
    border-radius: 4px 0 0 4px;
}

.dep-id-warning {
    padding-top: 8px;
}

.msg-info-code{
    font-weight: bold;
}