﻿@use 'sass:math';
@import 'variables';
@import 'mixins';

.inner-layout-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.container.web-pub-field {
    max-width: 100%;
}

.body-default .department-publish .web-pub-field:first-child {
    padding-top: 10px;
}

/*.web-pub-field.container-fluid .WebPubElement {
    margin-bottom: 0;
}

.web-pub-field.container-fluid + .container {
    padding-top: 10px;
}*/
.ColumnCenter .secondary-filter > div, .ColumnCenterWithSidebarLeft .secondary-filter > div {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

/**** Publiseringsmodus og slideshow ****/
.WebPubFieldRow {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.WebPubElement {
    margin-bottom: 5px;

    @include fromSizeSM() {
        margin-bottom: 10px;
    }
}

.slides_container .WebPubElement {
    margin-bottom: 0 !important;
}

.headroom .WebPubElement:last-child {
    margin-bottom: 0;
}

.royalSlider .WebPubFieldRow.LayoutVersion3{
    display:none;
}
.royalSlider .WebPubFieldRow.LayoutVersion3:first-child{
    display:flex; 
}

.royalSlider .rsContainer .WebPubFieldRow.LayoutVersion3{
    display:flex;
}

.layout-d2016_x2 {
    @include mobileLayout(2, 5px);

    @include fromSizeSM() {
        .WebPubElement {
            width: calc(50% - 5px);
            margin-left: 0;
            margin-right: 10px;

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
}

/* For slideshow we show the same number of elements per row regardless of the screen size*/
.layout-d2016_x2-slideshow .WebPubElement {
    width: calc(50% - 7.5px);
    margin-right: 5px;

    &:first-child {
        margin-left: 5px;
    }

    @include fromSizeSM() {
        width: calc(50% - 5px);
        margin-left: 0 !important;
        margin-right: 10px;

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}


.layout-d2016_x3 {
    @include mobileLayout(2, 5px);

    @include fromSizeSM() {
        .WebPubElement {
            margin-left: 0;
            margin-right: 10px;

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }

    @include fromSizeMD() {
        .WebPubElement {
            width: calc(33.33% - 6.67px);

            &:nth-child(2n) {
                margin-right: 10px;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}

/* For slideshow we show the same number of elements per row regardless of the screen size*/
.layout-d2016_x3-slideshow .WebPubElement {
    width: calc(33.33% - 6.67px);
    margin-right: 5px;

    &:first-child {
        margin-left: 5px;
    }

    @include fromSizeSM() {
        margin-left: 0 !important;
        margin-right: 10px;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}


.layout-d2016_x4 {
    @include mobileLayout(2, 5px);

    @include fromSizeSM() {
        .WebPubElement {
            width: calc(33.33% - 6.67px);
            margin-left: 0;
            margin-right: 10px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    @include fromSizeMD() {
        .WebPubElement {
            width: calc(25% - 7.6px);
            margin-left: 0;

            &:nth-child(3n) {
                margin-right: 10px;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}

/* For slideshow we show the same number of elements per row regardless of the screen size*/
.layout-d2016_x4-slideshow .WebPubElement {
    width: calc(25% - 6.25px);
    margin-right: 5px;

    &:first-child {
        margin-left: 5px;
    }

    &:nth-child(4n) {
        margin-right: 0;
    }
}


.layout-d2016_x5 {
    @include mobileLayout(2, 5px);

    @include fromSizeSM() {
        .WebPubElement {
            width: calc(33.33% - 6.67px);
            margin-right: 10px;
            margin-left: 0;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    @include fromSizeMD() {
        .WebPubElement {
            width: calc(20% - 8.5px);
            margin-left: 0;

            &:nth-child(3n) {
                margin-right: 10px;
            }

            &:nth-child(5n) {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}

/* For slideshow we show the same number of elements per row regardless of the screen size*/
.layout-d2016_x5-slideshow .WebPubElement {
    width: calc(20% - 8.5px);
    margin-left: 0;
    margin-right: 10px;

    &:nth-child(3n) {
        margin-right: 10px;
    }

    &:nth-child(5n) {
        margin-right: 0;
        margin-left: 0;
    }
}



.layout-d2016_x6 {
    @include mobileLayout(2, 5px);

    @include fromSizeSM() {
        .WebPubElement {
            width: calc(33.33% - 6.67px);
            margin-right: 10px;
            margin-left: 0;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    @include fromSizeMD() {
        .WebPubElement {
            width: calc(16.66% - 8.5px);
            margin-left: 0;

            &:nth-child(3n) {
                margin-right: 10px;
            }

            &:nth-child(6n) {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }
}

/* For slideshow we show the same number of elements per row regardless of the screen size*/
.layout-d2016_x6-slideshow .WebPubElement {
    width: calc(16.66% - 8.5px);
    margin-left: 0;
    margin-right: 10px;

    &:nth-child(3n) {
        margin-right: 10px;
    }

    &:nth-child(6n) {
        margin-right: 0;
        margin-left: 0;
    }
}





/* D2016 Fluid layout*/
$margin: 10px;
$maxWidth: 3840px;
$elementWidth: 300px;
$maxColumns: floor(math.div($maxWidth, $elementWidth));
$leftSideWidth: 250px;
$maxWidth: $elementWidth * $maxColumns;

.layout-d2016_fluid {
    .WebPubElement {
        margin-right: $margin;
    }
}

@for $i from 1 through $maxColumns {
    @media (min-width: ($elementWidth * $i)) {
        .layout-d2016_fluid .WebPubElement {
            width: calc(#{math.div(100%, $i)} - #{math.div($margin * ($i - 1), $i)});
            margin-right: $margin;

            &:nth-child(#{$i - 1}n) {
                margin-right: $margin;
            }

            &:nth-child(#{$i}n) {
                margin-right: 0;
            }
        }
    }
}

.ColumnCenter .layout-d2016_fluid .inner-layout-container {
    padding: 0 10px;
}

.pub-field-hide-xs,
.pub-field-hide-sm,
.pub-field-hide-md,
.pub-field-hide-lg,
.pub-field-hide-xl {
    display: none;
}



.ie9 {
    .WebPubElement {
        float: left;
    }
}

.preview-slideshow.web-pub-field.container .slides_container.ajax-field .WebPubElement{
    width:100%!important;
}

