﻿.express-cart-instuction {
    padding: 15px 15px 15px 0px;
}

.express-cart-prodlist {
    padding: 5px;
}

.express-cart-add {
    margin: 10px 0px;
}

.express-cart-error {
    margin-right: 50px;
}