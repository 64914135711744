﻿@import 'mixins';
.tilbehorsguide {
    .tilbehor-table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        background-color: transparent;
        border-spacing: 0;
        border-collapse: collapse;
        box-sizing: border-box;
        display: table;
        border-collapse: separate;
        border-spacing: 2px;
        border-color: grey;
    }  

    .tilbehor-select {
        display: none;
    }    

    .TilbehorDropdown {
        display: none;
    }

    .BuyButtonSmall {
        color: red;
        background-color: #fff;
        border-color: #ccc;
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        user-select: none;
        background-image: none;
        border: 1px solid;
        border-radius: 4px;
    }

    .tilbehor-buyselected {
        display: none;
    }

    .TilBehorsguideBilde {         
        text-align: center;
        margin-top: 10px;


    }

    .TilbehorTitle {
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
    }

    @include toSizeMD{
        .LagerColumn {
            display: none;
        }
        .AntallColumn {
            display: none;
        }
        .tilbehor-prissortextra {
        display: none;
        }

        .ProductListExtraPrice2 {
        display: none;
        }
    }

    @include fromSizeSM{
        .accessories-container{
            float: right;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        
    
    }
}
