﻿@import 'variables';
@import 'mixins';

$borderColor: #d4e0ec;
$borderPopupColor: #39c;
$favBackgroundColor: transparent;
$favColor: #39c;

/*Special offer article*/
.WebPubElement {
    .special-offers {
        border: none;
        background: transparent;
    }

    .panel {
        border-radius: 0;
        width: 100%;
    }
}

.dep-id-content .panel-group .panel,
.campaign-content .panel-group .panel {
    border: 1px solid #dedede;
    box-shadow: none;
}

#checkout .special-offers {
    width: 100%;
}

.special-offers {

    .camp-img {
        min-width: 64px;
        min-height: 64px;
        max-height: 64px;
        max-width: 64px;
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            max-width: 64px;
            height: auto;
            max-height: 64px;
            width: auto !important;
        }
    }

    .panel-heading {
        background: transparent;
        padding-left: 0;
        border: none;
    }

    .cartitem-text {
        color: $textColor;
        font-weight: normal;
        font-size: 18px;

        i {
            color: gray;
            font-size: 15px;
        }
    }

    .panel {
        border: none;
    }

    .panel-body {
        padding: 0;
        border: none;

        @include toSizeSM {
            padding: 0 5px;
            border-top-color: #ddd;
            max-height: 600px;
            margin-bottom: 10px;
            overflow-y: scroll;
        }
    }

    .autocamp-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ddd;
        margin-bottom: 15px;

        @include toSizeSM {
            padding-left: 0;
            position: relative;
            flex-wrap: wrap;
        }
    }

    .autocamp-item > div {
        flex: 0 1 auto;
    }

    .camp-text {
        width: 30%;
        text-align: left;

        > span {
            @include toSizeSM {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                display: inline-block;
            }
        }

        .cart-item-header {
            @include toSizeSM {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .cart-item-header2 {
            @include toSizeSM {
                display: none;
            }
        }

        @include toSizeSM {
            width: calc(100% - 130px);
            position: absolute;
            top: 3px;
            left: 80px;
            padding-left: 3px;
        }
    }

    .progress {
        margin-bottom: 0;
        border-radius: 0;

        @include toSizeSM {
            height: 15px;
        }
    }

    .progress-bar {
        border: none;
    }

    .camp-message-progress {
        width: 30%;

        @include toSizeSM {
            flex: 0 0 100%;
            width: 100%;
            margin: 7px 7px 0 7px;
        }

        .progress-complete {
            @include toSizeSM {
                display: block;
            }
        }
    }

    .camp-message-progress-outer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .camp-progress {
        width: 50%;
        margin-right: 20px;
        flex: 1 0 auto;

        @include toSizeSM {
            margin-right: 0;
            flex: 0 0 100%;
            width: 100%;
        }
    }

    .camp-message {
        
        text-transform: uppercase;
        font-size: 16px;

        @include toSizeSM {
            font-size: 11px;
            padding-left: 3px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            width: 100%;
            text-align: center;
        }
    }

    .camp-button {
        width: 120px;
        text-align: right;
        height: 70px;

        .btn {
            border-radius: 0;
            width: 120px;
            border-right: none;
            border-top: none;
            border-bottom: none;
            white-space: normal;
        }

        @include toSizeSM {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            width: 100%;
            height: 50px;
            -webkit-box-ordinal-group: 6;
            -ms-flex-order: 5;
            order: 5;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            margin-right: 6px;
        }
    }


    .camp-you-save {
        @include toSizeSM {
            margin-right: 5px;
        }
    }

    .camp-you-save-label {
        background: $youSaveBackground;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: block;
        font-size: 16px;
        text-align: center;
        padding-top: 14px;
        text-transform: uppercase;

        @include toSizeSM {
            display: block !important;
        }

        .cart-item-header {
            font-size: 16px;
        }
    }

    .you-save-in-nok {
        padding-top: 6px;

        .cart-item-header2,
        .cart-item-header {
            font-size: 12px;
        }
    }
}
/*Checkout*/
.body-checkout {

    .special-offers .panel {
        padding: 0 15px;
        margin-bottom: 0;
        border: 1px solid #ddd;
        border-radius: 4px;

        @include toSizeSM {
            padding: 0 10px;
        }
    }

    .web-pub-field {
        @include toSizeSM {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .camp-button {
        @include toSizeSM {
            margin-right: 0;
            order: 5;
            flex: 0 0 100%;
        }
    }
}
/*Page top widget*/
.body-checkout #content-special-offers {
    display: none;
}

#content-special-offers {
    float: right;
    display: inline-block;
    margin-top: 35px;

    @include toSizeSM {
        margin-top: 0;
        float: none;
        display: inline-block;
        margin-top: 4px;
        width: 100%;
        position: absolute;
        z-index: 1501;
    }

    .popover {
        @include toSizeSM {
            max-height: 700px;
        }
    }


    .special-offer-small-trigger {
        display: block;
        border: none;
        position: relative;
        padding: 8px;
        padding-bottom: 0;

        &:active,
        &:focus,
        &:hover {
            background: transparent;
            box-shadow: none;
        }

        @include toSizeSM {
            display: none;
            display: block;
            border: none;
            position: absolute;
            left: auto;
            right: 125px;
            padding: 4px;
            padding-bottom: 0;
            position: absolute;
            left: auto;
            right: 150px;
        }

        .btn.btn-default.special-offer-small-item {
            @include toSizeSM {
                height: 34px;
                width: 34px;
            }
        }
    }

    h3 {
        margin: 0;
        font-size: 14px;

        @include toSizeMD {
            display: none;
        }

        span {
            position: absolute;
            left: 5px;
            top: 45px;
            display: none;
        }
    }

    .glyphicon-tags {
        font-size: 27px;
        margin-right: 13px;
        float: left;

        @include toSizeSM {
            top: 2px !important;
            font-size: 24px;
        }
    }

    .special-offers-count {
        position: absolute;
        left: 17px;
        top: 3px;
        text-align: center;
        background: white;
        border: 2px #3399cc solid;
        width: 24px;
        border-radius: 50%;
        color: black;
        font-size: 14px;
        font-weight: bold;

        @include toSizeSM {
            top: 0;
        }
    }
}

.special-offers-small-popover {
    padding: 10px;
    padding-bottom: 0;
    width: 100%;
    max-width: 600px;

    @include toSizeSM {
        left: 15px !important;
        max-width: calc(100vw - 30px);
    }

    .popover-title {
        display: none;
    }

    .popover-content {
        padding: 0;
        margin-top: 25px;

        @include toSizeSM {
            margin-top: 33px;
        }
    }

    .special-offers {
        border: none;
    }

    .panel {
        margin-bottom: 0;
        width: 100%;
        box-shadow: none;
    }

    .panel-heading {
        display: none !important;
    }

    .autocamp-item {
        padding-left: 10px;
        margin-bottom: 10px;
    }

    .cart-item-header2 {
        display: none;
    }

    .camp-you-save-label {
        display: none;
    }

    .camp-message-progress-outer {
        display: block;
    }

    .camp-progress {
        width: 100%;
    }

    .camp-message {
        width: 100%;
        text-align: right;
        font-size: 14px;
    }

    .camp-button .btn {
        height: 55px;

        @include toSizeSM {
            height: 40px;
            border-left: none;
        }
    }
}
