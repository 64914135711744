﻿/*
@import 'variables';
@import 'mixins';
*/

.clickable-content {
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
}

#articlePopupContent.modal.fade.in {
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    .modal-dialog {
        display: flex;
        /*height: 100vh;*/
        margin-top: 0;
        margin-bottom: 0;
    }

    .modal-header {
        padding-bottom: 0px;
        border-bottom: none;
    }
}

.sidebar-collapse #articlePopupContent.modal.fade.in {
    padding-left: 50px;
    .modal-dialog {
        max-width: calc(100% - 50px);
    }
}

.sidebar-open #articlePopupContent.modal.fade.in {
    padding-left: 230px;
    .modal-dialog {
        max-width: calc(100% - 230px);
    }
}