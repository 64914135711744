﻿@import 'variables';

body {
    color: $textColor;
}
html, body, form, fieldset, table, tr, td, img {
	font-family: $defaultFont;
}
input, button, select, textarea, optgroup, option {
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
}

.copyright-container span {
	text-align: center;
}

p {
    margin: 0;
}