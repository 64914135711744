﻿@import 'mixins';

.v-container {
    display: inline-block;
    margin-bottom: 8px;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    border: 2px solid transparent;
    text-align: center;

    &.disabled {
        border: 2px solid #ededed;
        opacity: 0.3;
        background-color: #f6f5f5;
        cursor: pointer;

        &:hover {
            border: 2px solid grey;            
        }
    }

    &.activated {
        border: 2px solid #ededed;
        cursor: pointer;

        &:hover {
            border: 2px solid grey;
        }
    }

    &.colour-icon {
        margin: 5px 10px 8px 0px;
        padding: 0px;
        border: 2px solid white;
        border-radius: 50%;

        &.active {
            border: 2px solid black;
        }

        &:hover {
            border: 2px solid grey;
        }

        &.activated.active .color-preview-box {
            padding: 5px;
        }
    }



    &.active {
        border: 2px solid black;
    }

    &.small {
        padding-top: 1px;
        padding-bottom: 1px;
        margin-top: 5px;
    }

    &.li {
        display: block;
    }

    & span.variant-name {
        max-width: 60px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.dropdown-menu > li.has-colour > a {
    padding-left: 0px;
    display: inline-block;
}

.variant-container .dropdown-menu > .disabled > a:hover{
    cursor:pointer;
}

.dropdown-menu > li.has-colour div.color-preview-box {
    margin-left: 10px;
}

div.dropdown.variant-selector-container {
    display: inline-block;
    margin-right: 1em;
    margin-bottom: 8px;

    & li.v-container {
        padding: 0px;
        margin-right: 0px;
        border-radius: 0px;
        padding: 5px 2px;
        margin-bottom: 1px;
        border-width: 0px;
        border-bottom-width: 1px;

        &.active {
            border: none;
            background-color: #337ab7;
        }
        &:hover {
            background: #f5f5f5;
        }
    }

    & button.dropdown-toggle {
        min-width: 185px;

        & span.ddl-button-span {
            text-align: left;
            min-width: 120px;
            display: inline-block;
            margin-left: 10px;
        }

        & span.caret {
            text-align: right;
            display: inline-block;
        }
    }

    & ul.dropdown-menu {
        min-width: 185px;
    }
}

.dropdown.variant-selector-container .dropdown-menu {
    max-height: 200px;
    overflow: auto;
}

.variant-selector-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .vsc-heading,
    .vsc-heading2
    {
        width: 100%;
    }
}

.vimg-container {
    img {
        max-width: 50px;
        max-height: 50px;
    }

    a {
        display: block;
        line-height: normal;
    }
}

.vimg-container div.rsTmb{
    background-repeat:no-repeat;    
    background-size:contain;
}

.color-preview-box {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: text-bottom; 
}
ul.dropdown-menu .color-preview-box {
    margin-right: 10px;
    width: 18px;
    height: 18px;
} 

.color-preview-box-pch{ 
    min-width:18px;
    display:inline-block;
    & .color-preview-box {    
        width: 18px;
        height: 18px;
    }
}

.dropdown.variant-selector-container button .color-preview-box {
    width: 18px;
    height: 18px;
}
.preview-circle {
    border-radius: 50%;
    border: 2px solid #ededed;
}

.variant-selector-container .vsc-heading {
    margin-bottom: 5px;

    span {
        margin-right: 5px;
    }
}
.variant-selector-container a {
    color: black;

    &:hover {
        text-decoration: none;        
    }
}