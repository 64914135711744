﻿@import 'variables';
@import 'mixins';



.dynamic-contact-form {
    width: 100%;
    margin-bottom: 10px;

    .form-table {
        margin-top: 15px;

        label.form-label {
            @media (min-width: 768px) {
                width: 12em;
                text-align: right;
            }

            padding-right: 1em;
            padding-top: 4px;
            padding-bottom: 4px;
        }

        label.error {
            @media (min-width: 768px) {
                margin-left: 1em;
            }
        }

        .Text,
        .form-text-area,
        .contact-us-form-item-file {
            vertical-align: top;

            @media (min-width: 768px) {
                max-width: calc(500px - 12em);
            }

            width: 100%;
            display: inline-block;
        }

        .form-text-area {
            height: 8em;
        }

        .required.error {
            border: 2px orangered solid;
        }
    }

    .contact-us-form-result {
        margin-top: 10px;
    }
}
