﻿@use 'sass:math';

/* MIXINs */
@mixin font( $name, $src, $hash ) {
    @font-face {
        font-family: $name;
        src: url('fonts/' + $src + '.eot');
        src: url('fonts/' + $src + '.eot?#iefix') format('embedded-opentype'), url('fonts/' + $src + '.woff') format('woff'), url('fonts/' + $src + '.ttf') format('truetype'), url('fonts/' + $src + '.svg#' + $hash ) format('svg');
        font-weight: normal;
        font-style: normal;
    }
}

@mixin gradient($from-color, $to-color) {
    background-color: mix($from-color, $to-color); /* Fallback */
    background-image: linear-gradient(to bottom, $from-color, $to-color);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$from-color}', EndColorStr='#{$to-color}')";
}

@mixin fromSizeSM() {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin fromSizeMD() {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin fromSizeLG() {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin toSizeSM() {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin toSizeMD() {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin toSizeLG() {
    @media (max-width: 1199px) {
        @content;
    }
}


@mixin mobileLayout($elementsPerRow, $margin) {
    $width: math.div(100%, $elementsPerRow);
    $widthReduce: $margin * math.div($elementsPerRow + 1, $elementsPerRow);

    .WebPubElement {
        width: calc(#{$width} - #{$widthReduce});
        margin-right: $margin;
        margin-left: $margin;

        @if $elementsPerRow > 1 {
            &:nth-child(#{$elementsPerRow}n) {
                margin-left: 0;
            }
        }
    }
}

@mixin bootstrapContainer() {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @include fromSizeSM() {
        width: 750px;
    }

    @include fromSizeMD() {
        width: 970px;
    }

    @include fromSizeLG() {
        width: 1170px;
    }
}

@mixin multicaseIconFont() {
    font-family: "multicase-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin hardwareAccel() {
    // Improve performance on mobile/tablet devices
    // Perspective reduces blurryness of text in Chrome
    transform: perspective(1px) translateZ(0);
}

@mixin improveAntiAlias() {
    // Improve aliasing on mobile/tablet devices
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

@mixin hacks() {
    @include hardwareAccel();
    @include improveAntiAlias();
}

@mixin grow {
    @include hacks();
    transition-duration: 0.2s;
    transition-property: transform;

    &:hover,
    &:focus,
    &:active {
        transform: scale(1.1);
    }
}
