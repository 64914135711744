﻿@import 'mixins';

$white1: #fff !default;
$white2: #f1f1f1 !default;
$gray1: #e2e2e2 !default;
$gray2: #ccc !default;
$gray3: #666 !default;
$black1: #333 !default;
$blue1: #39c !default;

$blue1: #199bd7 !default;

/* Panel */
$filter-panel-background-color: none !default;
$filter-panel-with: 265px !default;
$filter-panel-close-background-color: $blue1 !default;
$filter-panel-close-active-background-color: $blue1 !default;


/* Group */
$filter-group-background-color: $white1 !default;
$filter-group-border: 1px solid $gray1;
$filter-group-padding: 10px 15px;
$filter-group-margin: 0px 0px 15px 0px;

/* Filter button */
$filter-button-border: 1px solid $gray2;

/* Attributes */
$filter-attr-color-hover: $gray3 !default;
$filter-attr-checked-text-color: $black1 !default;
$filter-attr-checked-color: $blue1 !default;
$filter-attr-font-size: 16px !default;

/* Box-elements */
$box-element-margin: 0px 0px 10px 0px;

/* =Search filter Attributter left side
-----------------------------------------------------------------------------*/
.box-section
{
    margin: $box-element-margin;
}

.box-section:last-child {
    margin-bottom: 0px;
}

@include toSizeMD {
    .ColumnCenterWithSidebarLeft {
        width: 100% !important;
        float: none !important;
        padding: 0 5px;
    }

    .mc-filter-left.search-result-page .PageContainerBorder {
        padding: 0;
    }

    .mc-filter-left .main-container .attribute-left-box {
        display: none;
    }

    .mc-filter-left #filter-btn {
        height: 39px;
        padding: 7px 12px;
        width: 100%;
        border-radius: 0;
        font-size: 16px;
        margin-bottom: 10px;

        @include fromSizeSM {
            left: 0px;
            width: calc(50% - 5px);
        }

        &.filter-btn-large {
            left: 10px;
            width: calc(100% - 20px);
        }
    }

    .mc-filter-left .filters-count {
        background-color: #f1f1f1;
        border-radius: 10px;
        padding: 0px 5px;
    }

    .mc-filter-left .FieldSorter .search-paging {
        display: block;
    }

    .mc-filter-left .FieldSorter select,
    .mc-filter-left .attribute-sort-box select {
        appearance: none !important;
        width: 100% !important;
        border: none;
        text-indent: 10%;
        padding: 7px 0;
    }

    .mc-filter-left .FieldSorterBottom .FieldSorter .search-paging {
        padding-top: 21px;

        @include fromSizeSM {
            width: calc(50% + 5px);
        }
    }

    .mc-filter-left .FieldSorter.web-pub-field-sort {
        margin-top: 10px;

        @include fromSizeSM {
            margin-top: 5px;
        }
    }

    .mc-filter-left .FieldSorterBottom .FieldSorter.web-pub-field-sort {
        margin-top: 0;
    }

    .mc-filter-left .PrdListCellsPagingTop {
        display: block;
        margin-bottom: 10px;
    }

    .c-menu--slide-left {
        overflow: visible !important;

        &.is-active .c-menu__close {
            padding: 5px 6px 6px 6px;
            background-color: $filter-panel-close-background-color;
            color: #fff;
            font-size: 22px;
        }

        .slide-left-header {
            background-color: $filter-panel-close-active-background-color;
            color: #fff;
            padding: 6px 20px 6px 0px;
            text-align: center;
            font-size: 21px;
            text-transform: uppercase;
        }
    }

    .ProductMenuContainer {
        display: none;
    }

    .c-menu--slide-left .ProductMenuContainer {
        display: block
    }
}
/* END toSizeMD */

#PageColumnCenter #productlist-sorter .dropdown-toggle {
    border-radius: 0px;
}

#PageColumnCenter {
    #filter-btn,
    #productlist-sorter {
        margin-bottom: 10px;
    }
}

@include fromSizeSM {
    #PageColumnCenter {

        #filter-btn + #productlist-sorter {
            .dropdown-toggle {
                height: 39px;
                border-radius: 0px;
            }
        }

        #productlist-sorter {
            float: right;
            max-width: calc(50% - 5px);
            margin-left: 5px;
        }

        .search-paging {
            width: auto;
            margin-bottom: 10px;
        }
    }
}

@include toSizeMD {
    .mc-filter-left #filter-btn,
    .mc-filter-left #filter-btn-cells,
    .mc-filter-left.ProductListCellsContainer #filter-btn-cells {
        background: url(/App_Themes/Demonstrare2016MASTER/Images/filter-icon.svg) no-repeat;
        background-position: left calc(50% - 3em) center;
        background-size: 30px;
    }

    .mc-filter-left .ProductListCellsContainer #filter-btn-cells {
        display: block !important;
        width: 100%;
        margin-bottom: 15px;
    }

    .mc-filter-left.sort-filter.listtype-grid .ProductListCellsContainer #filter-btn-cells {
        display: none !important;
    }

    .mc-filter-left.listtype-list .ProductListCellsContainer #filter-btn-cells,
    .mc-filter-left.sort-filter.listtype-list .ProductListCellsContainer #filter-btn-cells {
        display: block !important;
        width: 100%;
        margin-bottom: 15px;
    }
}

@media (max-width: 344px) {
    .c-menu--slide-left.is-active .c-menu__close {
        right: 0px !important;
        border-left: none !important;
        border-radius: 0px;
    }
}

.mc-filter-left .attribute-left-box .has-filters {
    padding: 15px 15px 50px 15px;
    background: $filter-panel-background-color;
    width: 100%;
    max-width: 100%;
    margin: 0;
}

@include fromSizeMD {
    .mc-filter-left .attribute-left-box .has-filters {
        padding: 0px 0px 10px 0px;
        margin-top: 0px;
    }

    .mc-filter-left.fullview.body-department .ProductMenu {
        padding-top: 3px;
    }

    .mc-filter-left.fullview .attribute-left-box .has-filters {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .mc-filter-left.no-breadcrumb .attribute-left-box .has-filters {
        margin-top: 0;
    }

    .mc-filter-left.has-no-filter .attribute-left-box {
        margin-top: 0px;
    }
}

.mc-filter-left .BreadCrumb {
    @include fromSizeMD {
        padding-left: 0px;
    }
}

.mc-filter-left.fullview .BreadCrumb {
    @include fromSizeMD {
        padding-left: 0;
    }
} 

.mc-filter-left .attribute-left-box #AttributeListBox {
    padding: 0;
}

.mc-filter-left .attribute-left-box .list-type-container {
    float: none;
    width: 100%;

    @include toSizeSM {
        font-size: $filter-attr-font-size;
    }
}

.mc-filter-left .attribute-left-box .list-type-label {
    display: inline-block;
    padding-right: 5px;
    padding-top: 6px;
}

.mc-filter-left .attribute-left-box .SelectedAttributeBox {
    width: 100%;
    height: auto;

    .ListButton,
    .GridButton {
        width: auto;
    }

    .list-type-button {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 25px;
        width: 31px;
    }

    .list-type-label {
        display: inline-block;
        padding-right: 5px;
        padding-top: 6px;
    }

    .LagerStatusContainer,
    .VariantGroupingContainer {

        @include toSizeSM {
            font-size: $filter-attr-font-size;
        }

        input[type=checkbox] {
            display: none;
        }

        label {
            font-weight: normal;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
        }

        label:before {
            content: '';
            width: 18px;
            height: 18px;
            display: inline-block;
            margin: 0px 10px 0px 0px;
            border: 1px solid #c6c6c6;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            font-size: 16px;
            vertical-align: top;
        }

        input[type=checkbox]:checked + label:before {
            content: "\e013";
            color: $filter-attr-checked-text-color;
        }
    }
}

.mc-filter-left .attribute-left-box .AttributeBox a.toggle {
    float: left;
    text-align: center;
    display: block;
    width: 100%;
    max-width: 100%;
    background: none;
    font-size: 0px;
    margin: 10px 0 0 0;

    &:before {
        content: "\e80e";
        color: $filter-attr-checked-text-color;
        position: relative;
        display: inline-block;
        font-family: 'multicase-icons';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 15px;
        text-align: center;
        width: 100%;
        border-radius: 2px;
        margin: 0;
        height: 35px;
        padding: 8px 0 0 0;
        border: 1px solid #c6c6c6;
    }


    &.hide {
        display: block !important;
        width: 100%;
        background: none;

        &:before {
            content: "\e80f";
        }
    }
}

.mc-filter-left .attribute-left-box .RemoveAllSelectedAttributes {
    height: auto;
}

.mc-filter-left .attribute-left-box .AttributeBox {
    background-color: $filter-group-background-color;
    border: $filter-group-border;
    padding: $filter-group-padding;
    margin: $filter-group-margin;
    width: 100%;
    float: none;

    &.AttributeHide {
        display: block !important;
    }

    .AttributeTitle {
        display: block;
        margin-bottom: 15px;
        margin-left: 0;
        padding-bottom: 0;
        width: 100%;
        color: #333;

        @include toSizeSM {
            font-size: $filter-attr-font-size;
        }
    }

    div:first-child {
        width: 100%;
        padding-right: 0;
        position: inherit;

        span.AttributeTitle {
            position: inherit;
        }
    }

    div.attribute-value {
        width: 100%;
        position: relative;
    }
}

.mc-filter-left .attribute-left-box .AttributeBox .attribute-value span {
    color: #333;
    font-weight: bold;
    margin-left: 5px;
    font-size: 14px;
    position: absolute;
    right: 0px;
}



.mc-filter-left .attribute-left-box .AttributeBox .attribute-value a {
    text-decoration: none;
    color: #333333;
    padding-left: 0px;
    cursor: pointer;
    display: inline-block;
    padding-bottom: 5px;
    vertical-align: top;
    max-width: calc(100% - 2.5em);
    text-overflow: ellipsis;
    overflow: hidden;

    @include toSizeSM {
        font-size: $filter-attr-font-size;
    }

    &:before {
        content: '';
        width: 18px;
        height: 18px;
        display: inline-block;
        margin: 0px 10px 0px 0px;
        border: 1px solid #c6c6c6;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        vertical-align: top;
    }


    &.selected:before {
        content: "\e013";
        color: $filter-attr-checked-text-color;
    }

    &:hover {
        text-decoration: none;
        color: $filter-attr-color-hover;
        font-weight: bold;

        &.selected:before {
            color: $filter-attr-color-hover;
        }
    }
}

.mc-filter-left .attribute-left-box .AttributeBox .attribute-value a.attribute-spinner {

    &:before {
        content: '\f110';
        animation: spin 2s infinite linear;
        font-family: "multicase-icons";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .2em;
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border:none;
    }
}

.mc-filter-left .attribute-left-box #AttributeListBox.attribute-spinner {
    &:after {
        content: '\f110';
        animation: spin 2s infinite linear;
        font-family: "multicase-icons";
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        /* opacity: .8; */
        /* For safety - reset parent styles, that can break glyph codes*/
        font-variant: normal;
        text-transform: none;
        /* fix buttons height, for twitter bootstrap */
        line-height: 1em;
        /* Animation center compensation - margins should be symmetric */
        /* remove if not needed */
        margin-left: .2em;
        /* you can be more comfortable with increased icons size */
        /* font-size: 120%; */
        /* Font smoothing. That was taken from TWBS */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border: none;
    }
}

.mc-filter-left .attribute-left-box #AttributeListBox .not-processed {
    display: none;
}

.mc-filter-left .attribute-left-box .SelectedAttributeBox {
    float: none;
    background-color: $filter-group-background-color;
    border: $filter-group-border;
    padding: $filter-group-padding;
    width: 100%;
    margin: $filter-group-margin;

    .filter-selection-container {
        a {
            font-weight: normal;
        }

        .SelectedAttribute a {
            &:after {
                content: "\e802";
                width: 18px;
                height: 18px;
                display: inline-block;
                color: $filter-attr-checked-color;
                position: relative;
                left: 4px;
                display: inline-block;
                font-family: 'multicase-icons';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
            }

            &:hover {
                border: none;
                text-decoration: none;
                color: #333;
                font-weight: bold;

                &:after {
                    font-weight: bold;
                }
            }
        }

        a.DeleteButton {
            display: inline-block;
            width: 100%;
            border: 1px solid #c6c6c6;
            padding: 5px 0px;
            border-radius: 2px;
            text-decoration: none;
            text-align: center;
            color: #666;

            &:hover {
                border: 1px solid #666;
                font-weight: bold;
            }

            &:after {
                content: "\e802";
                width: 18px;
                height: 18px;
                display: inline-block;
                color: $filter-attr-checked-color;
                position: relative;
                left: 4px;
                display: inline-block;
                font-family: 'multicase-icons';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
            }
        }
    }

    @include toSizeSM {
        font-size: $filter-attr-font-size;
    }
}

.mc-filter-left .attribute-left-box .SearchFilterMoreButton span.ShowMore {
    display: none;
}


/* Left product menu */

@include fromSizeSM {



    .mc-filter-left.fullview.body-department .ProductMenu {
        padding: 0px;
    }

    .mc-filter-left .ProductMenu ul {
        background-color: #fff;
        border: 1px solid #e2e2e2;
        padding: 3px 3px;
    } 

    .mc-filter-left .ProductMenu li {
        padding: 5px 10px;
        margin-bottom: 4px;
        border-bottom: 1px dotted #eee;
    }

    .mc-filter-left .ProductMenu li:last-child {
        border-bottom: none;
    }

    .mc-filter-left .ProductMenu li:after {
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .mc-filter-left .ProductMenu li:hover {
        font-weight: bold;
    }

    .mc-filter-left .ProductMenu .Level1Selected {
        background-color: #eee;
        border-radius: 0;
    }

    .mc-filter-left .ProductMenu .Level1Selected a {
        color: #434244;
        font-weight: bold;
    }

    .mc-filter-left .ProductMenu .Level2,
    .mc-filter-left .ProductMenu .Level2Selected {
        padding-left: 20px;
    }

    .mc-filter-left .ProductMenu .Level2Selected {
        background-position: 5px;
        font-weight: bold;
    }

    .mc-filter-left .ProductMenu .Level3,
    .mc-filter-left .ProductMenu .Level3Selected {
        padding-left: 35px;
    }

    .mc-filter-left .ProductMenu .Level3Selected {
        background-position: 20px;
        font-weight: bold;
    }

    .mc-filter-left .ProductMenu .Level4,
    .mc-filter-left .ProductMenu .Level4Selected {
        padding-left: 60px;
    }

    .mc-filter-left .ProductMenu .Level4Selected {
        font-weight: bold;
    }
}




/* Mobile / Left menu bar */
@include toSizeMD {

    #mmenu-left {
        width: 100%;
    }

    .mc-filter-left #PageColumnLeft {
        width: 100%;
        max-width: 100%;
        padding: 0;
    }

    .ProductMenuContainer .attribute-left-box.d2016 .container-2016 {
        max-width: 100%;
    }

    #mmenu-push-left,
    #mmenu-left {
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        height: 100%;
        overflow-y: scroll;
        background-color: $filter-panel-background-color;
    }
}

.fullview #PageColumnLeft {
    padding-bottom: 0;

    @include fromSizeMD {
        max-width: 250px;
        padding-right: 0;
    }
}

.fullview .container.breadcrumb-inner,
.fullview.custom-bg-active .breadcrumb-container .container {
    @include toSizeMD {
        width: 100%;
    }

    @include fromSizeMD {
        padding-left: $filter-panel-with + 18px;
    }
}

.fullview .BreadCrumb {
    @include toSizeMD {
        padding-left: 15px;
    }
}

@include fromSizeMD {

    .mc-filter-left.fullview.empty-product-menu .ProductMenu {
        visibility: hidden;
    }
}


.default-hidden {
    display: none;
}

.show-extra-values .default-hidden {
    display: block;
}


#filter-toggle {
    background: url(/App_Themes/Demonstrare4Dummy/Images/filter-icon.svg) no-repeat;
    background-position: left calc(25% - 15px) center;
    background-size: 30px;
    padding: 7px 12px;
    width: 100%;
    border-radius: 0;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    margin-top: 0;
    min-height: 38px;
    height: 38px;
}

.filter-content {
    margin-top: 15px;
}

.SelectedAttributeBox #productlist-sorter {
    margin: 0px 0px 10px 0px;
}

.attribute-list-search {
    width: 100%;
    border: 1px solid #c6c6c6;
    font-size: 14px;
    padding: .4em;
    margin-bottom: 0.625em;
    color: #333;
}

.attribute-list-search:focus {
    outline: none;
    border: 1px solid #4f4f4f;
}


#AttributeListBox .search-hidden {
    display: none;
}

.search-show-extra-values .default-hidden {
    display: block;
}

#AttributeListBox .toggle-hidden {
    position: absolute;
    left: -9999px;
}